import {ReactNode} from 'react'
import Header from 'src/components/headers/Header'

interface ExternalLayoutProps {
  children: ReactNode;
}

const ExternalLayout = ({children}: ExternalLayoutProps) => {
  return (
    <div className="relative flex flex-col w-full h-full min-h-full bg-kwota-neutral-100">
      <Header />
      <main className="w-full h-full mt-8">
        {children}
      </main>
    </div>
  )
}

export default ExternalLayout
