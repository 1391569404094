import {Icon} from '@kwota-cc/shared-components'
import React, {ReactNode} from 'react'

import {AccountTypes} from '../../types'

interface AccountTypeSelectProps {
  accountType: AccountTypes;
  active?: boolean;
  onClick: () => void;
  compact?: boolean;
  children?: ReactNode | ((active: boolean) => ReactNode);
}

const AccountTypeSelect = ({
  accountType,
  active = false,
  children,
  compact = false,
  onClick
}: AccountTypeSelectProps) => {
  const isPersonalAccount = accountType === AccountTypes.PERSONAL
  const cardText = isPersonalAccount
    ? 'Choose this to subscribe and compensate as an individual'
    : 'Choose this to subscribe and compensate as a business'

  const iconWrapClassNames = `${!compact && 'sm:mr-0 sm:p-4 sm:w-16 sm:h-16'} flex items-center justify-center w-10 h-10 p-2 mr-3 rounded-full bg-kwota-neutral-200`

  return (
    <button
      onClick={onClick}
      type="button"
    >
      <div
        className={`w-full p-4 rounded-2xl border space-y-3 hover:border-kwota-primary-500 hover:ring-2 hover:ring-kwota-primary-500 ${
          !compact && 'sm:p-6'
        } ${
          active
            ? 'border-kwota-primary-500 ring-2 ring-kwota-primary-500'
            : 'border-kwota-neutral-300'
        }`}
      >
        <div className={`flex flex-row ${!compact && 'sm:gap-4 sm:flex-col sm:items-center'}`}>
          <div className={iconWrapClassNames}>
            <Icon
              icon={isPersonalAccount ? 'user' : 'business'}
              size="full"
              className="fill-kwota-neutral-500"
            />
          </div>
          <div className={`${!compact && 'sm:text-center'} flex flex-col w-full gap-2 text-left text-kwota-neutral-800`}>
            <h3
              className={`${
                !compact && 'sm:text-xl'
              } text-lg font-semibold leading-6 font-titillium`}
            >
              <span className="capitalize">{accountType}</span> account
            </h3>
            <p className={`${!compact && 'sm:text-base'} text-sm leading-6 sm:text-base font-inter`}>
              {cardText}
            </p>
          </div>
        </div>
        {typeof children === 'function' ? children(active) : children}
      </div>
    </button>
  )
}

export default AccountTypeSelect
