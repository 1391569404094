import axios, {AxiosRequestConfig} from 'axios'

import {getAuthToken} from '../utils/auth'

interface ApiClient {
  authorization?: boolean;
}

const defaultClientConfig: ApiClient = {
  authorization: true
}

const apiClient = (clientConfig = defaultClientConfig) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  // @TODO token logic MUST be moved over to httpOnly Cookie. localStorage is vulnerable to xss attacks
  client.interceptors.request.use(
    (config): AxiosRequestConfig => {
      const token = getAuthToken()

      if (token && config.headers && clientConfig.authorization) {
        config.headers.authorization = `Bearer ${token}`
      }

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  return client
}

const requestGet = (url: string) => {
  return apiClient().request({url, method: 'get'})
}

const requestPost = <T>(url: string, payload?: T) => {
  return apiClient().request({url, method: 'post', data: payload})
}

const requestDelete = (url: string) => {
  return apiClient().request({url, method: 'delete'})
}

const requestUpdate = <T>(url: string, payload?: T) => {
  return apiClient().request({url, method: 'put', data: payload})
}

const unauthorizedRequestGet = (url: string) => {
  return apiClient({authorization: false}).request({url, method: 'get'})
}

const unauthorizedRequestPost = <T>(url: string, payload?: T) => {
  return apiClient({authorization: false}).request({url, method: 'post', data: payload})
}

export {apiClient, requestDelete, requestGet, requestPost, requestUpdate, unauthorizedRequestGet, unauthorizedRequestPost}
