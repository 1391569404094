import {createContext} from 'react'

export interface GlobalStateInterface {
  showSignupModal: boolean;
  showPaymentModal: boolean;
}

interface GlobalContextInterface {
  globalState: GlobalStateInterface;
  setGlobalState: (globalState: GlobalStateInterface) => void;
}

export const globalStateDefaultValue: GlobalStateInterface = {
  showSignupModal: false,
  showPaymentModal: false
}

export const GlobalContext = createContext<GlobalContextInterface>({
  globalState: globalStateDefaultValue,
  setGlobalState: () => {}
})
