const KEY_TOKEN = 'token'
const TRANSITORY_TOKEN = 'transitory-referenceId'

export const getAuthToken = (): string | null => {
  return localStorage.getItem(KEY_TOKEN)
}

export const setAuthToken = (jwtToken: string): void => {
  return localStorage.setItem(KEY_TOKEN, jwtToken)
}

export const removeAuthToken = (): void => {
  return localStorage.removeItem(KEY_TOKEN)
}

export const getTransitoryToken = (): string | null => {
  return localStorage.getItem(TRANSITORY_TOKEN)
}

export const setTransitoryToken = (referenceId: string): void => {
  return localStorage.setItem(TRANSITORY_TOKEN, referenceId)
}

export const removeTransitoryToken = (): void => {
  return localStorage.removeItem(TRANSITORY_TOKEN)
}
