import {createContext} from 'react'

export enum UserClientType {
  INTERMEDIARY_CARS ='intermediary_cars',
  REGULAR ='regular',
  BUSINESS = 'business',
  PERSONAL = 'personal'
}

export interface UserInterface {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  country: string;
  client: {
    id: string;
    name: string;
    type: UserClientType;
  };
}

interface UserContextInterface {
  user: UserInterface | null;
  setUser: (user: UserInterface | null) => void;
  isLoading: boolean;
}

export const userStateDefaultValue: UserInterface = {
  id: '',
  userName: '',
  firstName: '',
  lastName: '',
  country: '',
  client: {
    id: '',
    name: '',
    type: UserClientType.REGULAR
  }
}

const userContextDefaultValue = {
  user: userStateDefaultValue,
  setUser: () => {},
  isLoading: false
}

export const UserContext = createContext<UserContextInterface>(
  userContextDefaultValue
)
