import {ButtonLink, showToast} from '@kwota-cc/shared-components'
import {AxiosError} from 'axios'
import jwtDecode from 'jwt-decode'
import {useContext, useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import {ErrorWithFieldErrors, GeneralError} from 'src/api/types'
import {UserContext} from 'src/context/UserContext'
import useApi from 'src/hooks/useApi'
import {useLogin} from 'src/hooks/useAuth'
import useMultistepFlow from 'src/hooks/useMultistepFlow'
import {routes} from 'src/router/routes'
import {isErrorWithFieldErrors} from 'src/utils/api'
import {setAuthToken} from 'src/utils/auth'
import {setCssVieportHeight} from 'src/utils/calc'
import {COMPANY_INFO} from 'src/utils/constants'
import {isProdEnv} from 'src/utils/env'
import {useEventListener} from 'usehooks-ts'

import {clientSignup, userCheck, userLogin} from '../../api'
import {
  AuthProviderTypes,
  DecodedGoogleCredentials,
  SignupFormData,
  SignupPayload
} from '../../types'
import AccountTypeStep from './signupFlowSteps/AccountTypeStep'
import CountryStep from './signupFlowSteps/CountryStep'
import CredentialsStep from './signupFlowSteps/CredentialsStep'

const signupDefaultValues: SignupFormData = {
  accountType: undefined,
  fullName: '',
  countryCode: 'EE',
  companyName: '',
  email: '',
  password: ''
}

const SignupFlow = () => {
  const navigate = useNavigate()
  const {setUser} = useContext(UserContext)
  const {loginUser} = useLogin()

  const [previousVh, setPreviousVh] = useState(0)

  useEffect(() => {
    const vh = window.innerHeight * 0.01
    setPreviousVh(vh)
    setCssVieportHeight(vh)
  }, [])

  useEventListener('resize', () => {
    const vh = window.innerHeight * 0.01
    if(vh === previousVh) return
    setPreviousVh(vh)
    setCssVieportHeight(vh)
  })

  const [authProvider, setAuthProvider] = useState<AuthProviderTypes>(AuthProviderTypes.PASSWORD)
  const [providerAuthToken, setProviderAuthToken] = useState('')
  const [socialProviderLoading, setSocialProviderLoading] = useState(false)

  const [signupData, setSignupData] = useState(signupDefaultValues)
  const [errorMessage, setErrorMessage] = useState('')

  const {fullName, email, password, ...rest} = signupData
  const methods = useForm({
    defaultValues: {...rest},
    mode: 'onChange'
  })

  const {request: clientSignupRequest, loading: signupRequestLoading} = useApi<SignupPayload>()
  const {request: userCheckRequest, loading: userCheckLoading} = useApi<boolean>({
    success: res => res.data && setErrorMessage('This email is already in use'),
    error: () => setErrorMessage('Something went wrong')
  })

  const googleClientId = isProdEnv() ? '342886992469-vca69t5cu62nqq0levppfjh2jv6vemhh.apps.googleusercontent.com' : '351837244635-c2ou2kbc9rhg93nv40rimngqkpef11f4.apps.googleusercontent.com'

  const handleGoogleResponse = (
    response: google.accounts.id.CredentialResponse
  ) => {
    const decodedJwt: DecodedGoogleCredentials = jwtDecode(response.credential)
    const {name, email} = decodedJwt

    /* Not using useAuth as we don't want to navigate away, when user is not authenticated */
    setSocialProviderLoading(true)
    userLogin({
      username: email,
      provider: AuthProviderTypes.GOOGLE,
      providerAuthToken: response.credential,
      authOrigin: 'app'
    })
      .then(res => {
        setAuthToken(res.data.token)
        setUser(res.data.user)
        return navigate(routes.VEHICLE.LIST)
      })
      .catch(() => {
        setSignupData(prevState => {
          return {
            ...prevState,
            fullName: name,
            email
          }
        })

        setAuthProvider(AuthProviderTypes.GOOGLE)
        setProviderAuthToken(response.credential)

        return setStepTo(1)
      })
      .finally(() => setSocialProviderLoading(false))
  }

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: googleClientId,
      callback: handleGoogleResponse
    })

    google.accounts.id.renderButton(
      document.getElementById('googleSignInDiv') as HTMLElement,
      {theme: 'outline', type: 'standard', text: 'signin', locale: 'en'}
    )
  }, [])

  const onSubmit = async () => {
    const {companyName, accountType, countryCode} = methods.getValues()

    if (!accountType) return

    const signupPayloadBase = {
      fullName,
      email,
      companyName,
      accountType,
      countryCode
    }
    const signupPayload: SignupPayload =
      authProvider === AuthProviderTypes.PASSWORD
        ? {
          ...signupPayloadBase,
          password,
          provider: AuthProviderTypes.PASSWORD,
          providerAuthToken: ''
        }
        : {
          ...signupPayloadBase,
          provider: AuthProviderTypes.GOOGLE,
          providerAuthToken
        }

    clientSignupRequest(() => clientSignup(signupPayload))
      .then(() =>
        loginUser(
          authProvider === AuthProviderTypes.PASSWORD
            ? {username: email, password}
            : {
              username: email,
              provider: AuthProviderTypes.GOOGLE,
              providerAuthToken
            }
        )
      )
      .catch((err: AxiosError<ErrorWithFieldErrors | GeneralError>) => {
        const errorResponseData = err.response?.data

        let errorMessage = ''

        if (!errorResponseData) {
          errorMessage = 'Something went wrong'
        } else if (isErrorWithFieldErrors(errorResponseData)) {
          errorMessage = Object.values<string>(errorResponseData.fieldErrors)[0]
        }

        showToast({type: 'error', message: errorMessage})
      })
  }

  const {
    isFirstStep,
    currentStepIndex,
    currentStepComponent,
    setStepNext,
    setStepPrevious,
    setStepTo
  } = useMultistepFlow([
    <CredentialsStep
      defaultValues={{fullName, email, password}}
      errorMessage={errorMessage}
      onContinue={async credentials => {
        await userCheckRequest(() =>
          userCheck(credentials.email).then(res => {
            if (!res.data) {
              setSignupData(prevState => {
                return {...prevState, ...credentials}
              })
              setErrorMessage('')
              setStepNext()
            }
            return res
          })
        )
      }}
      loading={userCheckLoading || socialProviderLoading}
    />,
    <CountryStep onContinue={() => setStepNext()} />,
    <AccountTypeStep
      onSubmit={onSubmit}
      loading={signupRequestLoading}
    />
  ])

  return (
    <>
      <div
        id="main"
        className="relative flex flex-col p-4 min-h-[calc(var(--vh)*100)] md:min-h-full md:max-h-[693px] md:p-12 bg-kwota-neutral-100"
      >
        <header>
          <img
            src="/images/common/kwota-logo.svg"
            alt="KWOTA logo"
            className="h-6 mb-8 sm:hidden"
          />
          <h1 className="md:-mt-2 mb-10 text-[28px] sm:text-[40px] font-semibold text-center font-titillium sm:leading-12 text-kwota-neutral-800 ">
            Sign up with KWOTA
          </h1>
          {currentStepIndex !== 0 &&
            <ButtonLink
              onClick={setStepPrevious}
              text="Back"
              arrowDirection="left"
            />
          }
        </header>
        <div className="flex flex-col flex-grow space-y-5">
          <FormProvider {...methods}>
            <div className="flex flex-col flex-grow mt-4">
              {currentStepComponent}
            </div>
          </FormProvider>

          {/* Social sign-in */}
          {isFirstStep &&
            <div className="space-y-3">
              <div className="flex items-center">
                <div className="flex-1 h-[1px] bg-kwota-neutral-300 transform translate-y-0.5"></div>
                <p className="px-2 text-kwota-neutral-500 font-titillium">
                  Or continue with
                </p>
                <div className="h-[1px] flex-1 bg-kwota-neutral-300  transform translate-y-0.5"></div>
              </div>
              <div className="flex justify-center gap-4">
                <div id="googleSignInDiv" />
              </div>
            </div>
          }

          <div
            className={`${
              isFirstStep && 'border-b border-kwota-neutral-300'
            } pb-3 text-center font-titillium`}
          >
            <p className="text-kwota-neutral-800">
              Already have an account?
              <Link to={routes.LOGIN} className="pr-1 ml-1 text-xl font-semibold rounded-sm text-kwota-primary focus:outline-none focus:ring-2 focus:ring-kwota-primary hover:text-kwota-primary-600"> Log in
              </Link>
            </p>
          </div>
        </div>

        {isFirstStep &&
          <p className="pt-3 text-sm text-center md:px-12 font-titillium text-kwota-neutral-500 max-w-[444px] mx-auto">
            By continuing you agree to KWOTA's <a className="font-semibold text-kwota-primary" href={routes.TERMS_OF_SERVICE} target="_blank" rel="noreferrer">Terms of service </a>and <a className="font-semibold text-kwota-primary" href={routes.PRIVACY_POLICY} target="_blank" rel="noreferrer">Privacy Policy</a>&nbsp;
            Need help? <a className="font-semibold rounded-sm hover:text-kwota-primary-600 text-kwota-primary focus:outline-none focus:ring-2 focus:ring-kwota-primary" href={`mailto:${COMPANY_INFO.SUPPORT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer"> Get in touch</a>
          </p>
        }
      </div>
    </>
  )
}

export default SignupFlow
