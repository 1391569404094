import type {CountryCode} from '@kwota-cc/shared-components'
import {APP_STATUS} from 'src/utils/constants'

interface LoginDataPassword {
  username: string;
  password: string;
}

interface LoginDataSocialProvider {
  username: string;
  provider: SocialProviderTypes;
  providerAuthToken: string;
}

export type LoginData = LoginDataPassword | LoginDataSocialProvider;

export type LoginPayload = LoginData & {
  referenceId?: string;
  authOrigin: 'app';
}

export enum AccountTypes {
  PERSONAL = 'personal',
  BUSINESS = 'business'
}

export interface SignupFormData {
  accountType?: AccountTypes;
  fullName: string;
  companyName?: string;
  email: string;
  password: string;
  countryCode: CountryCode;
}

export interface SignupPayload extends Omit<SignupFormData, 'password' >{
  accountType: AccountTypes;
  password?: string;
  provider: AuthProviderTypes;
  providerAuthToken: string;
}

export interface ForgotPasswordFormValue {
  email: string;
}

interface VerificationLoading {
  type: APP_STATUS.LOADING;
}

interface VerificationError {
  type: APP_STATUS.ERROR;
}

interface VerificationSuccess {
  type: APP_STATUS.SUCCESS;
}

export type VerificationViewState = VerificationSuccess | VerificationLoading | VerificationError;

export interface ResetPasswordRequest {
  newPassword: string;
  confirmNewPassword: string;
}

export interface DecodedGoogleCredentials {
  // These six fields are included in all Google ID Tokens.
  iss: string;
  sub: string;
  azp: string;
  aud: string;
  iat: number;
  exp: number;

  // These seven fields are only included when the user has granted the "profile" and
  // "email" OAuth scopes to the application.
  email: string;
  email_verified: boolean;
  name: string;
  picture?: string;
  given_name: string;
  family_name: string;
  locale?: CountryCode;
}

export enum AuthProviderTypes {
  PASSWORD = 'password',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

const {PASSWORD} = AuthProviderTypes

export type SocialProviderTypes = Exclude<AuthProviderTypes, | typeof PASSWORD>;
