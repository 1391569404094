import axios, {AxiosError, AxiosResponse} from 'axios'
import {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {routes} from 'src/router/routes'

import {UserContext} from '../context/UserContext'
import {removeAuthToken} from '../utils/auth'

interface RequestFunctionInterface<T> {
  (): Promise<AxiosResponse<T>>;
}

export interface ErrorResponseData {
  error: string;
  message?: string;
}

export interface UseApiResponse<T> {
  data: T | null;
  error: string;
  loading: boolean;
  request: (apiFunc: RequestFunctionInterface<T>) => Promise<void>;
}

interface CallbackInterface<T> {
  success: (result: AxiosResponse<T>)=> void;
  error: (errorMessage:string) => void;
}

const defaultCallbacks = {
  success: () => {},
  error: () => {}
}

const generateErrorMessage = (err: AxiosError<ErrorResponseData>) => {
  return err.response?.data.error ?? 'Something went wrong. Please try again'
}

const useApi = <T>(callbacks: CallbackInterface<T> = defaultCallbacks): UseApiResponse<T> => {
  const navigate = useNavigate()
  const {setUser} = useContext(UserContext)
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const request = async (apiFunc: RequestFunctionInterface<T>) => {
    setLoading(true)
    try {
      const result = await apiFunc()
      setData(result.data)

      if (callbacks.success) callbacks.success(result)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(err.message || 'Unexpected Error!')

        if (err.response?.status === 401) {
          await removeAuthToken()
          setUser(null)
          navigate(routes.LOGIN)
        }

        if (err.response?.status === 404) {
          navigate(routes.NOT_FOUND)
        }

        if (callbacks.error) callbacks.error(generateErrorMessage(err as AxiosError<ErrorResponseData>))

        throw err
      } else {
        console.log(err)
      }

      // /** @TODO also need to handle 400 errors */
    } finally {
      setLoading(false)
    }
  }

  return {
    data,
    error,
    loading,
    request
  }
}

export default useApi
