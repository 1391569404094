import {AxiosResponse} from 'axios'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {GlobalContext} from 'src/context/GlobalContext'
import {userLogin} from 'src/features/auth/api'
import {LoginData, LoginPayload} from 'src/features/auth/types'
import {routes} from 'src/router/routes'

import {UserContext, UserInterface} from '../context/UserContext'
import {getTransitoryToken, removeTransitoryToken, setAuthToken} from '../utils/auth'
import useApi from './useApi'

export const useLogin = () => {
  const navigate = useNavigate()
  const {setUser} = useContext(UserContext)
  const {globalState, setGlobalState} = useContext(GlobalContext)

  const handleLoginSuccess = (
    res: AxiosResponse<{
      user: UserInterface;
      token: string;
    }>
  ) => {
    setAuthToken(res.data.token)
    setUser(res.data.user)
    navigate(routes.VEHICLE.LIST)
  }

  const {
    request: loginUserRequest,
    data,
    loading,
    error
  } = useApi<{user: UserInterface; token: string;}>({
    success: handleLoginSuccess,
    error: () => {}
  })

  const loginUser = (data: LoginData) => {
    const referenceId = getTransitoryToken() ?? undefined
    const payload: LoginPayload = {...data, referenceId, authOrigin: 'app'}
    return loginUserRequest(() => userLogin(payload)).then(() => {
      if(referenceId) {
        removeTransitoryToken()
        setGlobalState({
          ...globalState,
          showSignupModal: false,
          showPaymentModal: true
        })
      }
    })
  }

  return {loginUser, data, loading, error}
}
