import type {CountryCode} from '@kwota-cc/shared-components'

import Forbidden from '../assets/images/error/403.svg'
import Lock from '../assets/images/error/lock.svg'
import NoImageFound from '../assets/images/error/no-image-found.png'
import FacilityBanner from '../assets/images/facility/default.png'

const LogoBlue = '/images/common/kwota-logo.svg'

export const ASSETS = {
  LOGO_BLUE: LogoBlue,
  NO_IMAGE_FOUND: NoImageFound,
  FACILITY_PLACEHOLDER: FacilityBanner,
  FORBIDDEN: Forbidden,
  LOCK: Lock
}

export const Countries: {key: CountryCode; label: string;}[] = [
  {key: 'GB', label: 'United Kingdom'},
  {key: 'EE', label: 'Estonia'},
  {key: 'RU', label: 'Russia'},
  {key: 'LV', label: 'Latvia'},
  {key: 'LT', label: 'Lithuania'}
]

export enum APP_STATUS {
  CONFIRMED = 'CONFIRMED',
  DATA = 'DATA',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  OK = 'OK'
}

export const COMPANY_INFO = {
  SUPPORT_EMAIL_ADDRESS: 'support@kwota.com',
  TOS_DOC_LINK: 'https://docs.google.com/document/d/1CVkgc63VBs1SSALQWXI0YXs6QY6SZE93/edit'
}
