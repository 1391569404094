import {Button, CountrySelect} from '@kwota-cc/shared-components'
import {useFormContext} from 'react-hook-form'

interface CountryStepProps {
  onContinue: () => void;
}

const CountryStep = ({onContinue}: CountryStepProps) => {
  const methods = useFormContext()
  const formCountry = methods.watch('countryCode')
  return (
    <>
      <h2 className="text-lg font-semibold font-titillium text-kwota-neutral-800">Choose your country of residence</h2>
      <div className="mt-4 mb-48 space-y-4">
        <CountrySelect selectedValue={formCountry} setSelectedValue={countryCode => methods.setValue('countryCode', countryCode)} />
      </div>
      <div className="mt-auto">
        <Button text="Continue" onClick={onContinue} type="button" fullWidth />
      </div>
    </>
  )

}

export default CountryStep
