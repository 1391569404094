export const calculateVat = (priceWithoutVat: number): number => {
  const VAT = 0.22
  return Number((priceWithoutVat * VAT).toFixed(2))
}

export const getPriceWithVat = (priceWithoutVat: number): number => {
  return Number((priceWithoutVat + calculateVat(priceWithoutVat)).toFixed(2))
}

export const subtract = (subtractable: string, amount: string): number => {
  return Number((Number(subtractable) - Number(amount)).toFixed(2))
}

export const setCssVieportHeight = (vh: number) => {
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
