import {Icon} from '@kwota-cc/shared-components'

interface MessageProps {
  message: string;
  type: 'error' | 'success';
}

const ApiMessage = ({type, message}: MessageProps) => {
  const wrapColorClassNames =
    type === 'error'
      ? 'text-kwota-alert-400 bg-kwota-alert-100'
      : 'text-kwota-green-400 bg-kwota-green-100'

  return (
    <div
      className={`${wrapColorClassNames} flex gap-3 px-4 py-2 mb-4 rounded-lg font-titillium items-center`}
    >
      <div className="flex-shrink-0">
        {type === 'error' &&
          <Icon
            icon="error"
            className={
              type === 'error' ? 'fill-kwota-alert-400' : 'fill-kwota-green-400'
            }
          />
        }
      </div>
      {message}
    </div>
  )
}

export default ApiMessage
