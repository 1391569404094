import {Button, Form, FormInput} from '@kwota-cc/shared-components'
import {RegisterOptions} from 'react-hook-form'
import ApiMessage from 'src/components/ApiMessage'
import {containsNumbers, emailRegEx, fullNameRegEx, pwRegEx} from 'src/utils/form'

export interface CredentialFormData {
  fullName: string;
  email: string;
  password: string;
}

interface CredentialsStepProps {
  onContinue: (formData: CredentialFormData) => void;
  defaultValues: CredentialFormData;
  errorMessage?: string;
  loading?: boolean;
}

const fullNameFieldRules: RegisterOptions = {
  required: {value: true, message: 'This field is required'},
  pattern: {value: fullNameRegEx, message: 'Valid first and last name is required'},
  validate: value => {
    if (containsNumbers(value)) return 'Name cannot contain numbers'
    return true
  }
}

const passwordFieldRules: RegisterOptions = {
  required: {value: true, message: 'This field is required'},
  minLength: {value: 8, message: 'Must contain at least 8 characters'},
  pattern: {
    value: pwRegEx,
    message: 'Must contain uppercase letters, lowercase letters and digits'
  }
}

const emailFieldRules: RegisterOptions = {
  required: {value: true, message: 'This field is required'},
  pattern: {value: emailRegEx, message: 'Please provide a validw email'}
}

const CredentialsStep = ({onContinue, defaultValues, errorMessage, loading = false}: CredentialsStepProps) => {

  return (
    <Form onSubmit={onContinue} className="flex flex-col flex-grow gap-8" defaultValues={defaultValues}>
      {({formState}) => {
        const {isDirty, errors} = formState
        return (
          <>
            <FormInput
              rules={fullNameFieldRules}
              fullWidth
              name="fullName"
              type="text"
              label="Full name"
              autoComplete="name"
            />
            <FormInput
              rules={emailFieldRules}
              fullWidth
              name="email"
              type="text"
              label="Email address"
              autoComplete="email"
            />
            <FormInput
              rules={passwordFieldRules}
              fullWidth
              name="password"
              type="password"
              label="Create password"
              autoComplete="new-password"
            />
            {errorMessage && <ApiMessage type="error" message={errorMessage} />}
            <div className="mt-auto">
              <Button
                fullWidth
                disabled={(!isDirty && !errors) || loading}
                type="submit"
                text="Continue"
                loading={loading}
              />
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default CredentialsStep
