import {COMPANY_INFO} from 'src/utils/constants'

export const routes = {
  ROOT: '/',

  //Public only routes
  LANDING: '/landing',
  LOGIN: '/login',
  SIGNUP: '/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:hash',
  TRANSITORY_VEHICLES: {
    INDEX: '/transitory-vehicles',
    ADD_VEHICLES: '/transitory-vehicles/:licensePlateOrVin'
  },

  //Private routes
  DASHBOARD: '/dashboard',
  USER: {
    ROOT: '/user'
  },
  VEHICLE: {
    LIST: '/vehicles'
  },
  CUSTOMER: {
    LIST: '/customers'
  },

  //Invalid or Error routes
  INVALID: '*',
  NOT_FOUND: '/404',
  NOT_AUTHORIZED: '/403',

  //Global routes
  TERMS_OF_SERVICE: COMPANY_INFO.TOS_DOC_LINK,
  PRIVACY_POLICY: COMPANY_INFO.TOS_DOC_LINK,
  FACILITIES: {
    INDEX: '/facilities',
    FACILITY: '/facilities/:facilityCode'
  },
  RALLY_ESTONIA: {
    INDEX: '/rallyestonia',
    OVERVIEW: '/rallyestonia/overview',
    COMPENSATE: '/rallyestonia/compensate',
    EN: {
      INDEX: '/en/rallyestonia',
      OVERVIEW: '/en/rallyestonia/overview'
    }
  },
  RALLY_ESTONIA_2023: {
    INDEX: '/rallyestonia/2023',
    OVERVIEW: '/rallyestonia/2023/overview',
    COMPENSATE: '/rallyestonia/2023/compensate',
    PAYMENT_RESULT: '/payment/result',
    EN: {
      INDEX: '/en/rallyestonia/2023',
      OVERVIEW: '/en/rallyestonia/2023/overview'
    }
  },
  RALLY_FINLAND: {
    INDEX: '/rallyfinland',
    INDEX_EN: '/en/rallyfinland'
  }
}
