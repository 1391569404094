import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {routes} from 'src/router/routes'

import {UserContext} from '../context/UserContext'
import {userLogout} from '../features/auth/api'
import {removeAuthToken} from '../utils/auth'

const useLogout = () => {
  const navigate = useNavigate()
  const {setUser} = useContext(UserContext)

  const executeLogoutSequence = () => {
    removeAuthToken()
    setUser(null)
    navigate(routes.LOGIN)
  }

  const logoutUser = async () => {
    return userLogout()
      .then(() => {
        executeLogoutSequence()
      })
      .catch(() => {
        /**
         * Even though the logout request failed on the API side we can and should
         * still logout the user on the app side.
         * @TODO Log the error on the service level
         */
        executeLogoutSequence()
      })
  }

  return {
    logoutUser
  }
}

export default useLogout
