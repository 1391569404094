import {Button, Input} from '@kwota-cc/shared-components'
import {useFormContext} from 'react-hook-form'
import {AccountTypes} from 'src/features/auth/types'

import AccountTypeSelect from '../AccountTypeSelect'

interface AccountTypeStepProps {
  onSubmit: () => void;
  loading?: boolean;
}

const AccountTypeStep = ({onSubmit, loading}: AccountTypeStepProps) => {
  const methods = useFormContext()
  const formAccountType = methods.watch('accountType')
  const formCompanyName = methods.watch('companyName')

  const fieldRegisterProps = methods.register('companyName', {
    required: {value: true, message: 'This field is required'},
    minLength: {value: 3, message: 'Minimum company name length is 3'}
  })

  return (
    <>
      <h2 className="text-lg font-semibold font-titillium text-kwota-neutral-800">Choose your account type</h2>
      <div className="mt-4 mb-8 space-y-4">
        <AccountTypeSelect
          accountType={AccountTypes.PERSONAL}
          active={formAccountType === AccountTypes.PERSONAL}
          onClick={() => {
            methods.setValue('companyName', undefined)
            return methods.setValue('accountType', AccountTypes.PERSONAL)
          }}
          compact
        />
        <AccountTypeSelect
          accountType={AccountTypes.BUSINESS}
          active={formAccountType === AccountTypes.BUSINESS}
          onClick={() => methods.setValue('accountType', AccountTypes.BUSINESS)}
          compact
        >
          {active => {
            if (active) {
              return (
                <div className="pb-1">
                  <Input
                    {...fieldRegisterProps}
                    value={formCompanyName}
                    label="Company name"
                    hasError={methods.formState.errors.companyName ? true : false}
                    errorMessage={
                      methods.formState.errors.companyName?.message as string
                    }
                    type="text"
                    fullWidth
                  />
                </div>
              )
            }
          }}
        </AccountTypeSelect>
      </div>
      <div className="mt-auto">
        <Button
          onClick={() => {
            methods.trigger('companyName')
            if (formAccountType === AccountTypes.BUSINESS && !formCompanyName) return
            if (Object.keys(methods.formState.errors).length > 0) return
            return onSubmit()
          }}
          text="Sign up"
          fullWidth
          loading={loading}
          disabled={loading || !formAccountType}
        />
      </div>
    </>
  )
}

export default AccountTypeStep
