import {DashboardLayout as SharedDashboardLayout} from '@kwota-cc/shared-components'
import {ProfileMenuItem, SidebarMenuItems} from '@kwota-cc/shared-components/dist/components/layout/types'
import React, {ReactNode, useContext, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {routes} from 'src/router/routes'

import Loading from '../../components/Loading'
import {UserClientType, UserContext, UserInterface} from '../../context/UserContext'
import {createCustomerPortalSession, getUserSubscription} from '../../features/subscription/api'
import {ClientSubscription} from '../../features/subscription/types'
import useApi from '../../hooks/useApi'
import useLogout from '../../hooks/useLogout'

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({children}: DashboardLayoutProps) => {
  const {user, isLoading} = useContext(UserContext)
  const {logoutUser} = useLogout()

  const useGetSubscription = useApi<ClientSubscription>()

  useEffect(() => {
    useGetSubscription.request(() => getUserSubscription())
  }, [])

  const handleBillingClick = () => {
    createCustomerPortalSession().then(response => {
      const {portalUrl} = response.data
      window.location.href = portalUrl
    })
  }

  const getProfileMenuItems = (): ProfileMenuItem[] => {
    const items: ProfileMenuItem[] = []
    if (useGetSubscription.data) {
      items.push({
        icon: 'invoice',
        text: 'Billing',
        onClick: handleBillingClick
      })
    }
    return items
  }

  if (isLoading) {
    return <Loading />
  }

  if (!user) {
    return <Navigate to={routes.ROOT} />
  }

  const menuItems = generateNavItemProps(user)
  const userFullName = user?.firstName + ' ' + user?.lastName

  return (
    <SharedDashboardLayout
      organization={{name: userFullName}}
      profileMenuItems={getProfileMenuItems()}
      menuItems={menuItems}
      onLogout={logoutUser}
      children={children}
    />
  )
}

const generateNavItemProps = (user: UserInterface | null) => {

  const commonSidebarMenuItems: SidebarMenuItems[] = [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'columns-outline',
    //   hidden: false
    // },
    {
      name: 'Vehicles',
      url: '/vehicles',
      icon: 'car',
      hidden: false
    }
  ]

  const vehiclesResellerMenuItems: SidebarMenuItems[] = [
    ...commonSidebarMenuItems
    // {
    //   name: 'Customers',
    //   url: '/customers',
    //   icon: 'user-many',
    //   hidden: false
    // }
  ]

  const vehiclesClientMenuItems: SidebarMenuItems[] = [
    ...commonSidebarMenuItems
    // {
    //   name: 'Payments',
    //   url: '/payments',
    //   icon: 'invoice',
    //   hidden: false
    // }
  ]

  switch (user?.client.type) {
    case UserClientType.INTERMEDIARY_CARS:
      return vehiclesResellerMenuItems
    default:
      return vehiclesClientMenuItems
  }
}

export default DashboardLayout
