import * as React from 'react'

import Header from '../../components/headers/Header'

interface LayoutProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  headerTheme?: string;
  children?: React.ReactNode;
}

const BaseLayout = ({id, className, style, children}: LayoutProps) => {
  return (
    <div id={id} style={style} className={`bg-[#F3F7FB] min-h-screen ${className || ''}`} >
      <Header linkToLogin />
      {children}
    </div>
  )
}

export default BaseLayout
