import {ReactElement, useState} from 'react'

const useMultistepFlow = (stepComponents: ReactElement[]) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const totalSteps = stepComponents.length
  const isLastStep = currentStepIndex === totalSteps - 1
  const isFirstStep = currentStepIndex === 0

  const setStepNext = () => {
    setCurrentStepIndex(index => {
      if (index >= totalSteps - 1) return index
      return index + 1
    })
  }

  const setStepPrevious = () => {
    setCurrentStepIndex(index => {
      if (index <= 0) return index
      return index - 1
    })
  }

  const setStepTo = (index: number) => {
    setCurrentStepIndex(index)
  }

  return {
    currentStepIndex,
    currentStepComponent: stepComponents[currentStepIndex],
    stepComponents,
    isFirstStep,
    isLastStep,
    setStepTo,
    setStepNext,
    setStepPrevious
  }
}

export default useMultistepFlow
