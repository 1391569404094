import {AxiosResponse} from 'axios'

import {requestGet, requestPost} from '../../api/client'
import {ClientSubscription, ConfigurationResponse, CustomerPortalSessionResponse, IntentResponse} from './types'

export const getStripeConfiguration = (): Promise<AxiosResponse<ConfigurationResponse>> => {
  return requestGet('/payment/config')
}

export const createCustomerPortalSession = (): Promise<AxiosResponse<CustomerPortalSessionResponse>> => {
  return requestGet('/payment/portal-session')
}

export const getUserSubscription = (): Promise<AxiosResponse<ClientSubscription>> => {
  return requestGet('/payment/subscription')
}

export const createSubscription = (json: string): Promise<AxiosResponse<IntentResponse>> => {
  return requestPost('/payment/subscription', json)
}

export const updateSubscription = (json: string): Promise<AxiosResponse<void>> => {
  return requestPost('/payment/subscription/update', json)
}
