import {AxiosResponse} from 'axios'

import {requestGet, requestPost, unauthorizedRequestPost} from '../../api/client'
import {UserInterface} from '../../context/UserContext'
import {LoginPayload, ResetPasswordRequest, SignupPayload} from './types'

const userLogin = (
  payload: LoginPayload
): Promise<AxiosResponse<{user: UserInterface; token: string;}>> =>
  unauthorizedRequestPost('/user/login', payload)

const clientSignup = (
  payload: SignupPayload
): Promise<AxiosResponse<SignupPayload>> =>
  unauthorizedRequestPost('/client/signup', payload)

const userLogout = (): Promise<AxiosResponse<void>> =>
  requestPost('/user/logout')

const userValidate = (): Promise<AxiosResponse<{user: UserInterface;}>> =>
  requestGet('/user/validate')

const forgotPassword = (payload: string): Promise<AxiosResponse<void>> =>
  unauthorizedRequestPost('/user/forgot-password', payload)

const userCheck = (email: string): Promise<AxiosResponse<boolean>> =>
  requestGet(`/user/check/${email}`)

const validateHash = (hash: string): Promise<AxiosResponse<void>> =>
  requestPost(`user/validate-hash?hash=${hash}`)

const resetPassword = (payload: ResetPasswordRequest, hash: string): Promise<AxiosResponse<void>> => {
  return requestPost(`user/reset-password?hash=${hash}`, JSON.stringify(payload))
}

export {clientSignup, forgotPassword, resetPassword, userCheck, userLogin, userLogout, userValidate, validateHash}
