import {DropdownOption} from '@kwota-cc/shared-components/dist/components/dropdown/Dropdown'
import {FieldValues, RegisterOptions, UseFormRegister} from 'react-hook-form'

export type FormRegister = UseFormRegister<FieldValues>

export const generateUseFormRegisterProps = (name?: string, register?: FormRegister, registerOptions?: RegisterOptions) => {
  if (!name || !register) return null
  return {...register(name, registerOptions)}
}

/*
   * ^            Start anchor
   * (?=.*[A-Z])  Ensure string has at least one uppercase letters.
   * (?=.*[0-9])  Ensure string has at least one digit.
   * (?=.*[a-z])  Ensure string has at least one lowercase letters.
   * .{8,}        Ensure string is minimum of length 8.
   * $            End anchor.
*/

export const pwRegEx = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/

export const emailRegEx =
// eslint-disable-next-line
/^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/

/*
   * \w    Any word character (letters, digits, and underscores).
   * .     Any character except for a line break.
   * +     One or more occurrences of the preceding character or group.
   * \s    Any whitespace character (spaces, tabs, and line breaks).
*/
export const fullNameRegEx = /(\w.+\s).+/

export const containsNumbers = (str: string): boolean => /[0-9]/.test(str)

export const stringArrayToDropdownOptions = (stringArray: string[] | null): DropdownOption[] => {
  if(!stringArray) return []
  return stringArray.map(string => {
    return {id: string, value: string}
  })
}
