import {DashboardLayout as SharedDashboardLayout, Icon, Modal, showToast} from '@kwota-cc/shared-components'
import {SidebarMenuItems} from '@kwota-cc/shared-components/dist/components/layout/types'
import React, {ReactNode, useContext, useEffect, useState} from 'react'
import Loading from 'src/components/Loading'
import {GlobalContext} from 'src/context/GlobalContext'
import SignupFlow from 'src/features/auth/signup/components/SignupFlow'
import {getReferenceId} from 'src/features/transitoryVehicles/api'
import {routes} from 'src/router/routes'
import {getTransitoryToken, setTransitoryToken} from 'src/utils/auth'

const PublicDashboardLayout = ({children}: {children: ReactNode;}) => {
  const {globalState, setGlobalState} = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(!getTransitoryToken()) fetchReferenceId()
  }, [])

  const fetchReferenceId = () => {
    setLoading(true)
    getReferenceId()
      .then(response => {
        if (!response.data) {
          return showToast({type: 'info', message: 'ReferenceId not found, please try again'})
        }
        setTransitoryToken(response.data.referenceId)
      })
      .catch(() => {
        showToast({type: 'error', message: 'Something went wrong, please try again'})
      })
      .finally(() => setLoading(false))
  }

  const sidebarMenuItems: SidebarMenuItems[] = [
    {
      name: 'Vehicles',
      url: routes.TRANSITORY_VEHICLES.INDEX,
      icon: 'car',
      hidden: false
    }
  ]

  const handleOnProfileMenuClick = () => {
    setGlobalState({
      ...globalState,
      showSignupModal: true
    })
  }

  const handleOnCloseModal = () => {
    setGlobalState({
      ...globalState,
      showSignupModal: false
    })
  }

  if(loading) return <Loading />

  return (
    <>
      <Modal visible={globalState.showSignupModal} onClose={handleOnCloseModal} className="w-full md:max-w-[525px] overflow-scroll md:rounded-2xl md:shadow-kwota-min transform transition-all duration-[800ms] flex flex-col">
        <div className="relative self-end mr-4">
          <Icon icon="cross" className="absolute z-10 -ml-6 top-4" onClick={handleOnCloseModal} />
        </div>
        <SignupFlow />
      </Modal>

      <SharedDashboardLayout
        organization={{name: 'User'}}
        menuItems={sidebarMenuItems}
        onLogout={handleOnProfileMenuClick}
        children={children}
        isAuthenticated={false}
      />
    </>
  )
}

export default PublicDashboardLayout
