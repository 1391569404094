import {useEffect, useState} from 'react'
import {getAuthToken} from 'src/utils/auth'

import {UserInterface} from '../context/UserContext'
import {userValidate} from '../features/auth/api'

export default function useValidateAuth () {
  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useState<UserInterface | null>(null)

  useEffect(() => {
    async function isAuthTokenValid () {
      await userValidate()
        .then(async res => {
          setUser(res.data.user)
        }).catch(() => {
          /**
           * This block handles errors and also expiry of tokens.
           * E.g when token expiry date is passed then it will return 401
           * @TODO think what is the correct approach to handle those
           */
        })
    }
    if(getAuthToken()) {
      isAuthTokenValid().then(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  return {
    user,
    setUser,
    isLoading
  }
}
