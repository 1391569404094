import {Link} from 'react-router-dom'
import {routes} from 'src/router/routes'

interface HeaderProps {
  linkToLogin?: boolean;
}

const Header = ({linkToLogin = false}: HeaderProps) => {
  const logoUrl = '/images/common/kwota-logo.svg'

  return (
    <div
      id="nav"
      className="container flex items-end justify-between h-16 px-8 mx-auto sm:px-0"
    >
      <Link to={routes.ROOT}>
        <img className="h-6" src={logoUrl} alt="Kwota logo" />
      </Link>
      {linkToLogin &&
        <Link to={routes.LOGIN} className="text-xl font-semibold font-titillium text-kwota-primary-500">
          Log in
        </Link>
      }
    </div>
  )
}
export default Header
