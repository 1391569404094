import '@kwota-cc/shared-components/dist/tailwind.css'
import './assets/styles/index.css'

import {Toaster} from '@kwota-cc/shared-components'
import React from 'react'
import {createRoot} from 'react-dom/client'
import TagManager from 'react-gtm-module'

import App from './App'
import {isProdEnv} from './utils/env'

const root = createRoot(
  document.getElementById('root') as HTMLElement
)

if(isProdEnv()) {
  TagManager.initialize({gtmId: 'GTM-W7JGGLN'})
}

root.render(
  <>
    <Toaster />
    <App />
  </>
)
