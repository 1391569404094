import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {UserContext} from 'src/context/UserContext'
import useValidateAuth from 'src/hooks/useValidateAuth'
import {routes} from 'src/router/routes'

const PrivateRoute = () => {
  const {user, setUser, isLoading} = useValidateAuth()

  if(isLoading) return <></>
  if (!user) return <Navigate to={routes.ROOT} replace />
  return (
    <UserContext.Provider value={{user, setUser, isLoading}}>
      <Outlet />
    </UserContext.Provider>
  )
}

const PublicOnlyRoute = () => {
  const {user, setUser, isLoading} = useValidateAuth()

  if(isLoading) return <></>
  if (user) return <Navigate to={routes.VEHICLE.LIST} replace />
  return (
    <UserContext.Provider value={{user, setUser, isLoading}}>
      <Outlet />
    </UserContext.Provider>
  )
}

const GlobalRoute = () => {
  return <Outlet />
}

export {GlobalRoute, PrivateRoute, PublicOnlyRoute}
