import {PageNotFound} from '@kwota-cc/shared-components'
import React, {lazy, Suspense} from 'react'
import {RouteObject} from 'react-router-dom'
import BaseLayout from 'src/layouts/baseLayout/BaseLayout'
import CleanLayout from 'src/layouts/cleanLayout/CleanLayout'
import ExternalLayout from 'src/layouts/externalLayout/ExternalLayout'
import LayoutView from 'src/layouts/LayoutView'
import PublicDashboardLayout from 'src/layouts/publicDashboardLayout/PublicDashboardLayout'

import Loading from '../components/Loading'
import DashboardLayout from '../layouts/dashboardLayout/DashboardLayout'
import {GlobalRoute, PrivateRoute, PublicOnlyRoute} from './redirects'
import {routes} from './routes'

const SuspenseLoader = (Component: React.ComponentType) => () =>
  <Suspense fallback={<Loading />}>
    <Component />
  </Suspense>

const Landing = SuspenseLoader(lazy(() => import('../features/landing/Landing')))
const Login = SuspenseLoader(lazy(() => import('../features/auth/login/Login')))
const Signup = SuspenseLoader(lazy(() => import('../features/auth/signup/Signup')))
const ForgotPassword = SuspenseLoader(lazy(() => import('../features/auth/forgotPassword/ForgotPassword')))
const ResetPassword = SuspenseLoader(lazy(() => import('../features/auth/resetPassword/ResetPassword')))

// const Dashboard = SuspenseLoader(lazy(() => import('../features/dashboard/Dashboard')))
// const Customers = SuspenseLoader(lazy(() => import('../features/customers/Customers')))
const Vehicles = SuspenseLoader(lazy(() => import('../features/vehicles/features/VehiclesPage')))

const Facilities = SuspenseLoader(lazy(() => import('../features/facilities/Facilities')))
const Facility = SuspenseLoader(lazy(() => import('../features/facilities/facility/Facility')))

const RallyEstoniaLandingEn = SuspenseLoader(lazy(() => import('../features/rallyEstonia/en/RallyEstonia')))
const RallyEstoniaOverviewEn = SuspenseLoader(lazy(() => import('../features/rallyEstonia/en/overview/Overview')))
const RallyEstoniaLandingEst = SuspenseLoader(lazy(() => import('../features/rallyEstonia/est/RallyEstonia')))
const RallyEstoniaOverviewEst = SuspenseLoader(lazy(() => import('../features/rallyEstonia/est/overview/Overview')))

const RallyEstonia2023LandingEn = SuspenseLoader(lazy(() => import('../features/rallyEstonia/2023/en/RallyEstonia')))
const RallyEstonia2023OverviewEn = SuspenseLoader(lazy(() => import('../features/rallyEstonia/2023/en/overview/Overview')))
const RallyEstonia2023LandingEst = SuspenseLoader(lazy(() => import('../features/rallyEstonia/2023/est/RallyEstonia')))
const RallyEstonia2023OverviewEst = SuspenseLoader(lazy(() => import('../features/rallyEstonia/2023/est/overview/Overview')))
const RallyEstoniaPaymentResult = SuspenseLoader(lazy(() => import('../features/rallyEstonia/2023/result/RallyEstoniaPaymentResult')))

const RallyFinlandLandingEng = SuspenseLoader(lazy(() => import('../features/rallyFinland/en/RallyFinland')))
const RallyFinlandLandingFin = SuspenseLoader(lazy(() => import('../features/rallyFinland/fi/RallyFinland')))

const TransitoryVehicles = SuspenseLoader(lazy(() => import('src/features/transitoryVehicles/TransitoryVehicles')))

const publicOnlyRoutes: RouteObject = {
  element: <PublicOnlyRoute />,
  children: [
    {
      element: <LayoutView Component={CleanLayout} />,
      children: [
        {path: routes.SIGNUP, element: <Signup />},
        {path: routes.LOGIN, element: <Login />},
        {path: routes.FORGOT_PASSWORD, element: <ForgotPassword />},
        {path: routes.RESET_PASSWORD, element: <ResetPassword />}
      ]
    },
    {
      element: <LayoutView Component={PublicDashboardLayout} />,
      children: [
        {path: routes.TRANSITORY_VEHICLES.INDEX, element: <TransitoryVehicles />},
        {path: routes.TRANSITORY_VEHICLES.ADD_VEHICLES, element: <TransitoryVehicles />}
      ]
    }
  ]
}

const privateRoutes: RouteObject = {
  element: <PrivateRoute />,
  children: [
    {
      element: <LayoutView Component={DashboardLayout} />,
      children: [
        // {path: routes.DASHBOARD, element: <Dashboard />},
        // {path: routes.CUSTOMER.LIST, element: <Customers />}
        {path: routes.VEHICLE.LIST, element: <Vehicles />}
      ]
    }
  ]
}

const globalRoutes: RouteObject = {
  element: <GlobalRoute />,
  children: [
    {
      element: <LayoutView Component={ExternalLayout} />,
      children: [
        {path: routes.FACILITIES.INDEX, element: <Facilities />},
        {path: routes.FACILITIES.FACILITY, element: <Facility />}
      ]
    },
    {
      element: <LayoutView Component={BaseLayout} />,
      children: [
        {path: routes.LANDING, element: <Landing />}
      ]
    },
    {
      element: <LayoutView Component={CleanLayout} />,
      children: [
        {path: routes.RALLY_ESTONIA.INDEX, element: <RallyEstoniaLandingEst />},
        {path: routes.RALLY_ESTONIA.OVERVIEW, element: <RallyEstoniaOverviewEst />},
        {path: routes.RALLY_ESTONIA.EN.INDEX, element: <RallyEstoniaLandingEn />},
        {path: routes.RALLY_ESTONIA.EN.OVERVIEW, element: <RallyEstoniaOverviewEn />},

        // 2023 Rally Estonia event
        {path: routes.RALLY_ESTONIA_2023.INDEX, element: <RallyEstonia2023LandingEst />},
        {path: routes.RALLY_ESTONIA_2023.OVERVIEW, element: <RallyEstonia2023OverviewEst />},
        {path: routes.RALLY_ESTONIA_2023.PAYMENT_RESULT, element: <RallyEstoniaPaymentResult />},
        {path: routes.RALLY_ESTONIA_2023.EN.INDEX, element: <RallyEstonia2023LandingEn />},
        {path: routes.RALLY_ESTONIA_2023.EN.OVERVIEW, element: <RallyEstonia2023OverviewEn />}
      ]
    },
    {
      element: <LayoutView Component={CleanLayout} />,
      children: [
        {path: routes.RALLY_FINLAND.INDEX, element: <RallyFinlandLandingFin />},
        {path: routes.RALLY_FINLAND.INDEX_EN, element: <RallyFinlandLandingEng />}
      ]
    }

  ]
}

export const router: RouteObject[] = [
  privateRoutes,
  publicOnlyRoutes,
  globalRoutes,
  {path: '/404', element: <PageNotFound />},
  {path: '*', element: <PageNotFound />}
]
