import {AxiosResponse} from 'axios'
import {requestDelete, requestGet, requestPost} from 'src/api/client'

import {ClientVehicle, FetchVehicleAverageEmissionsPayload, Vehicle} from '../vehicles/types'
import {GetTransitoryVehiclePriceRequest, TransitoryVehicleBaseRequest, TransitoryVehiclePayload} from './types'

export const getTransitoryVehicleData = (licensePlate: string): Promise<AxiosResponse<Vehicle | null>> => {
  return requestGet(`/vehicle/data/${licensePlate}`)
}

export const getTransitoryVehiclePrice = ({
  payload
}: GetTransitoryVehiclePriceRequest): Promise<AxiosResponse<number>> =>
  requestPost('/vehicle/price', payload)

export const fetchTransitoryVehicleAverageEmissions = ({
  payload
}: {
  payload: FetchVehicleAverageEmissionsPayload;
}): Promise<AxiosResponse<{averageEmissions: number;}>> =>
  requestPost('/vehicle/emissions', payload)

export const getReferenceId = (): Promise<AxiosResponse<TransitoryVehicleBaseRequest>> => {
  return requestGet('/transitory-vehicle/reference')
}

export const createTransitoryVehicle = (payload: TransitoryVehiclePayload): Promise<AxiosResponse<ClientVehicle>> =>
  requestPost('/transitory-vehicle/create', payload)

export const getTransitoryVehicles = (referenceId: string): Promise<AxiosResponse<ClientVehicle[]>> =>
  requestGet(`/transitory-vehicle/${referenceId}`)

export const deleteTransitoryVehicle = (vehicleId: string) =>
  requestDelete(`/transitory-vehicle/${vehicleId}`)
