import {useState} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'

import {GlobalContext, globalStateDefaultValue} from './context/GlobalContext'
import {router} from './router'

const App = () => {
  const [globalState, setGlobalState] = useState(globalStateDefaultValue)

  return (
    <GlobalContext.Provider value={{globalState, setGlobalState}}>
      <RouterProvider router={createBrowserRouter(router)} />
    </GlobalContext.Provider>
  )
}

export default App
